<template>
  <b-card no-body class="card-statistics" v-if="data">
    <!-- <b-card-header>
      <b-card-title>{{ $t("fields.statistics") }}</b-card-title>
    </b-card-header> -->
    <b-card-body class="statistics-body">
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th style="width:40%">MTD</th>
                    <th style="width:40%">YTD</th>
                </tr>
            </thead>
            <tbody>

                <tr>
                    <th>Income</th>
                    <td class="text-success">{{ data.income_mtd || 0 }}</td>
                    <td class="text-success">{{ data.income_ytd || 0 }}</td>
                </tr>
                <tr>
                    <th>Expense</th>
                    <td class="text-danger">{{ data.expense_mtd || 0 }}</td>
                    <td class="text-danger">{{ data.expense_ytd || 0 }}</td>
                </tr>
            </tbody>
        </table>
      <!-- <b-row>
        <b-col xl="6" sm="12" class="mt-2">
          <h5 class="text-start">
            {{ $t("fields.monthtodate") }}
          </h5>
          <b-media no-body class="mt-1 align-middle">
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon size="24" icon="TrendingUpIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <span
                  style="font-family: rupee; font-size: 15px"
                  class="fw-bolder"
                  >&#8377;</span
                >
                {{ data.income_mtd || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("fields.incomemtd") }}
              </b-card-text>
            </b-media-body>
          </b-media>
          <b-media no-body class="mt-1 align-middle">
            <b-media-aside class="mr-2 mt-1">
              <b-avatar size="48" variant="light-danger">
                <feather-icon size="24" icon="TrendingDownIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <span
                  style="font-family: rupee; font-size: 15px"
                  class="fw-bolder"
                  >&#8377;</span
                >
                {{ data.expense_mtd || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("fields.expensemtd") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="6" sm="12" class="mt-2">
          <h5 class="text-start">
            {{ $t("fields.yeartodate") }}
          </h5>
          <b-media no-body class="mt-1 align-middle">
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon size="24" icon="TrendingUpIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <span
                  style="font-family: rupee; font-size: 15px"
                  class="fw-bolder"
                  >&#8377;</span
                >
                {{ data.income_ytd || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("fields.incomeytd") }}
              </b-card-text>
            </b-media-body>
          </b-media>
          <b-media no-body class="mt-1 align-middle">
            <b-media-aside class="mr-2 mt-1">
              <b-avatar size="48" variant="light-danger">
                <feather-icon size="24" icon="TrendingDownIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <span
                  style="font-family: rupee; font-size: 15px"
                  class="fw-bolder"
                  >&#8377;</span
                >
                {{ data.expense_mtd || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("fields.expenseytd") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row> -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
